// 搜索类型的 Select 下拉框列表
const searchTypeList = [
  {
    value: 1,
    label: "坯布指示单号"
  },
  {
    value: 2,
    label: "合同号"
  },
  {
    value: 3,
    label: "客户名称"
  },
  {
    value: 4,
    label: "客户款号"
  }
];

// 机台下发状态列表 —— checkboxGroup 复选框组
const transferOrderStatusList = [
  {
    value: 2,
    label: "生产中"
  },
  {
    value: 4,
    label: "指定完工"
  }
];

export { searchTypeList, transferOrderStatusList };
