<template>
  <div id="page-complete-production">
    <!-- 顶部筛选 -->
    <div class="common-filter-block">
      <div class="common-filter-item">
        <Select v-model="listParam.searchType" style="width: 120px">
          <Option v-for="item in searchTypeList" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.searchText"
          @keyup.enter.native="getTableListData"
          style="width: 200px"
        />
      </div>
      <div class="common-filter-item">
        <span style="text-align: right; margin-right: 10px">机台号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.machineNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <div class="filter-checkbox">
          <Checkbox
            :indeterminate="isIndeterminate"
            :value="isCheckAll"
            @click.prevent.native="handleCheckAll"
            >全选</Checkbox
          >
          <span style="padding: 0 8px; color: #dcdada">|</span>
          <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
            <Checkbox
              v-for="item in transferOrderStatusList"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</Checkbox
            >
          </CheckboxGroup>
        </div>
      </div>
    </div>
    <!-- 自定义的表格操作栏 -->
    <div class="common-action-block">
      <div class="common-action-item" @click="getTableListData">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <div class="common-action-item" @click="completeProduction">
        <i class="iconfont iconicon-queren"></i>
        指定完工
      </div>
      <div class="common-action-item" @click="exportDeviceList">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
    </div>

    <!-- 表格 -->
    <Table
      ref="selection"
      :border="true"
      :loading="loading"
      :columns="tableColumns"
      :data="tableData"
      @on-selection-change="selectionChange"
    >
      <template slot-scope="{ row }" slot="greyClothSheetNo">
        <span class="common-able-click" @click="goToDetailPage(row)">{{
          row.greyClothSheetNo
        }}</span>
      </template>
      <template slot-scope="{ row }" slot="statusName">
        <span style="margin-right:15px">{{row.statusName}}</span>
        <span class="common-able-click" @click="changeStatusAgain(row)" v-if="row.statusName != '生产中' ">恢复生产</span>
      </template>
    </Table>
    <!-- 分页 -->
    <Page
      class-name="common-page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
  </div>
</template>

<script>
import { searchTypeList, transferOrderStatusList } from "./constant";
import { fetchDeviceViewList, fetchCompleteProduction, fetchChangeStatus } from "./api";

export default {
  name: "CompleteProduction",
  data() {
    return {
      // 筛选栏 复选框组的部分
      isCheckAll: false,
      isIndeterminate: false,
      checkAllGroup: [], // 和 this.listParam.statusList 有关联

      currentRowIndex: -1, // 首页表格当前操作的行的index
      ztdvIdList: [], // 首页已选择的机台视图id列表，包括对应的状态
      ztdvZtppIdList: [], // 首页已选择的排产表id列表

      // 首页表格列表的请求参数
      listParam: {
        searchType: 1, // 1:坯布指示单号,2:合同号,3:客户名称,4:款式（客户款号）
        searchText: "",
        machineNo: "",
        statusList: "2,4", // 下发状态 "2,4"， 2生产中，4指定完工

        pageNum: 1,
        pageSize: 10,
      },

      // 首页表格配置
      loading: false,
      tableColumns: [
        {
          type: "selection",
          width: 54,
          align: "center",
        },
        {
          title: "序号",
          type: "index",
          width: 66,
        },
        {
          title: "状态",
          slot: "statusName",
          key: "statusName",
          width: 100,
        },
        {
          title: "机台号",
          key: "machineNo",
          width: 90,
        },
        {
          title: "坯布指示单号",
          slot: "greyClothSheetNo",
          key: "greyClothSheetNo",
          width: 140,
        },
         {
          title: "指定完工日期",
          key: "endDate",
          width: 156,
        },
        {
          title: "计划下发日期",
          key: "transferOrderDate",
          width: 156,
        },
        {
          title: "合同号",
          key: "contractNo",
          width: 140,
        },
        {
          title: "客户款号",
          key: "clientMoneyNo",
          minWidth: 160,
        },
        {
          title: "坯布货号",
          key: "greyClothGoodsNo",
          width: 130,
        },

        {
          title: "实际下发日期",
          key: "factTransferOrderDate",
          width: 156,
        },
        {
          title: "计划日期",
          key: "planDate",
          width: 156,
        },
        {
          title: "预计完工日期",
          key: "predictFinishDate",
          width: 130,
        },
        {
          title: "交货日期",
          key: "deliveryDate",
          width: 130,
        },
        {
          title: "客户名称",
          key: "clientName",
          minWidth: 140,
        },
        {
          title: "颜色",
          key: "color",
          minWidth: 170,
        },
        {
          title: "备注",
          key: "remark",
          minWidth: 180,
        },
        {
          title: "修改日志",
          key: "modifyLog",
          minWidth: 500,
        },
      ],

      tableData: [],

      listDataTotal: 0, // 分页器数据总条数

      searchTypeList,
      transferOrderStatusList,
    };
  },
  created() {
    this.getTableListData();
  },
  watch: {
    checkAllGroup: {
      handler(newVal, oldVal) {
        this.getTableListData();
      },
      immediate: true,
    },
  },
  methods: {
    // 复选框组单向联动单个的全选复选框
    checkAllGroupChange(data) {
      if (data.length === this.transferOrderStatusList.length) {
        // 全选状态
        this.isIndeterminate = false;
        this.isCheckAll = true;
      } else if (data.length > 0) {
        // 部分选择状态
        this.isIndeterminate = true;
        this.isCheckAll = false;
      } else {
        // 全不选状态
        this.isIndeterminate = false;
        this.isCheckAll = false;
      }

      this.listParam.statusList = data.join(",");
    },

    // 全选复选框的单击事件
    handleCheckAll() {
      if (this.isIndeterminate) {
        this.isCheckAll = false;
      } else {
        this.isCheckAll = !this.isCheckAll;
      }
      this.isIndeterminate = false;

      // 全选复选框单向联动相应的复选框组
      if (this.isCheckAll) {
        this.checkAllGroup = this.transferOrderStatusList.map((item) => item.value);
        this.listParam.statusList = this.transferOrderStatusList
          .map((item) => item.value)
          .join(",");
      } else {
        this.checkAllGroup = [];
        this.listParam.statusList = "";
      }
    },

    getTableListData() {
      this.loading = true;
      fetchDeviceViewList(this.listParam)
        .then((res) => {
          if (res.data.success === 1) {
            const { list } = res.data.body;

            this.tableData = list.list;
            this.listDataTotal = list.total;
            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },

    // 指定完工(可以同时指定多个，多选)
    completeProduction() {
      if (this.ztdvIdList.length === 0) {
        this.$Message.warning("请先选择生产中的机台！");
        return;
      }

      // 注意：every()方法如果测试的是 空数组 也会返回 true
      if (!this.ztdvIdList.every((item) => item.status === 2)) {
        this.$Message.warning("只有生产中的机台才可以指定完工！");
        return;
      }

      const ztdvIds = this.ztdvIdList.map((item) => item.ztdvId);

      fetchCompleteProduction({
        ztdvIdList: ztdvIds,
        ztdvZtppIdList: this.ztdvZtppIdList,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("指定完工操作成功！");
            this.getTableListData();
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 导出表格
    exportDeviceList() {
      const { pageNum, pageSize, ...data } = this.listParam;
      const dataStr = this.qs.stringify(data);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/order/ProductPlanController/deviceViewExport?${dataStr}`;
    },

    // 首页多选发生改变
    selectionChange(selection) {
      this.ztdvIdList = selection.map((item) => {
        return {
          ztdvId: item.ztdvId,
          status: item.status,
        };
      });

      const ztdvZtppIdArray = selection.map((item) => item.ztdvZtppId); // 没去重的数组
      this.ztdvZtppIdList = Array.from(new Set(ztdvZtppIdArray)); // 数组去重
    },

    goToDetailPage({ greyClothSheetNo }) {
      this.$router.push({
        path: "/workOrderManage/clothSheetDetail",
        query: { ztgcpoiWeaveDetail: greyClothSheetNo },
      });
    },
    changeStatusAgain({ ztdvId, status, ztdvZtppId, machineNo }){
      fetchChangeStatus({ ztdvId: ztdvId, status: status, ztdvZtppId: ztdvZtppId, ztdvMachineNo: machineNo }).then((res) => {
          if (res.data.success === 1) {
           this.getTableListData();
          } else {
            this.$Message.error(res.data.msg);
          }
        }).catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";

.filter-checkbox {
  display: flex;
  width: max-content;
}
</style>
<style lang="scss">
#page-complete-production {
  
}
</style>
